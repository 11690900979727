import React from "react";
import { MainTextContainer, FirstParagraph, SecondParagraph } from './HomePage';

function ContactPage(props) {
  return (
    <MainTextContainer>
    <FirstParagraph>
Belangstelling voor een beeld in hoge resolutie?
    </FirstParagraph>

    <SecondParagraph>
Peter van Koningsbruggen<br/>

vankoningsbruggen@kpnmail.nl<br/>

06 42945620
    </SecondParagraph>
    </MainTextContainer>
  );
}

export default ContactPage;
