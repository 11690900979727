import React, { useEffect, useState, useCallback } from "react";
import { useParams } from 'react-router-dom';
import PhotoGallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";
import { GalleryMargin } from '../utils/sizes';


function Gallery(props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
      setViewerIsOpen(false);
      setCurrentImage(0);
  };

  const { topic } = useParams();
  const { photos } = props;

  if ( !(topic in photos) ){
    return null
  }

  var current_photos = photos[topic];

  current_photos.map(x => x.loading = 'lazy');

  return (
  <>
    <PhotoGallery 
      photos={current_photos} 
      margin={GalleryMargin} 
      onClick={openLightbox}
      targetRowHeight={500}
    />
    <ModalGateway>
      {viewerIsOpen ?
        <Modal onClose={closeLightbox} style={{display: 'none'}}>
          <Carousel
            currentIndex={currentImage}
            views={current_photos.map(x => ({
              ...x,
              srcset: x.srcSet,
              caption: x.title
            }))}
            hideControlsWhenIdle={3000}
          />
	</Modal>
      : null}
    </ModalGateway>
  </>
  );
}

export default React.memo(Gallery);
