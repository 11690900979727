import axios from 'axios'

export const AXIOS = axios.create({
  baseURL: 'https://api.mondialphocus.nl',
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
	},
})

export function getData(topic) {
  return AXIOS.get(`/drive_photos`)
}

