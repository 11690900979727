import React from "react";

function LoadingSpinner() {
  return (
    <div className="loadingio-spinner-eclipse-an5dlyxg6xb">
      <div className="ldio-cu94rqdrawn">
        <div></div>
      </div>
    </div>
  )
}

export default LoadingSpinner;
