import React from "react";
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import eye from '../sources/eye.svg';
import { Breakpoint } from '../utils/sizes';

function CustomLogo(props) {
  return (
    <LogoContainer to="/" exact>
      <TopText>
        MONDIAL PH<Eye src={eye} className="eye-logo" alt="logo" />CUS
      </TopText>
      <BottomText>
        PHOTOGRAPHY
      </BottomText>
    </LogoContainer>
  );
}

const Eye = styled.img`
  max-height: 22px;
  margin-right: 1px;

  @media (min-width: ${Breakpoint.s}px) {
    max-height: 26px;
  }

  @media (min-width: ${Breakpoint.m}px) {
    max-height: 30px;
  }
`

const LogoContainer = styled(NavLink)`
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  hyphens: none;

  @media (min-width: ${Breakpoint.m}px) {
    margin-left: 15px;
  }

  &:hover {
    text-decoration: none;
  }
`

const TopText = styled.div`
  color: #c00000;
  font-size: 22pt;
  font-weight: 500;

  @media (min-width: ${Breakpoint.s}px) {
    font-size: 26pt;
  }

  @media (min-width: ${Breakpoint.m}px) {
    font-size: 30pt;
  }
`

const BottomText = styled.div`
  color: #2f5597;
  font-size: 11pt;
  font-weight: 200;

  @media (min-width: ${Breakpoint.s}px) {
    font-size: 13pt;
  }

  @media (min-width: ${Breakpoint.m}px) {
    font-size: 15pt;
  }
`

export default CustomLogo;
