import React from "react";
import styled from 'styled-components';
import { Breakpoint } from '../utils/sizes';

function HomePage(props) {
  return (
    <MainTextContainer>
      <FirstParagraph>
        Passie voor schrijven wedijvert met die voor reizen en fotograferen.
      </FirstParagraph>

      <SecondParagraph>
        Het heeft me naar zo’n veertig landen gebracht. 
        Opleiding in de journalistiek en studie van de antropologie droegen bij aan beter observeren, wat mijn oog voor fotografische compositie en thema heeft gescherpt. 
        Het heeft ook opgeleverd me snel te bewegen in diverse sociale settings, waardoor kortstondige wederkerigheid en begrip tussen fotograaf en gefotografeerde bereikt worden, een momentum van authenticiteit en spontaneïteit. 
        In het beste geval leidt fotograferen tot de sensatie van een schier poëtische relatie met de mij omringende wereld. Dan onderga ik het vastleggen van beelden als dichten, met de wereld als taal/verhaal.
        Hier ontmoet de fotograaf de schrijver de reiziger. 
        De laatste jaren gaat mijn belangstelling meer en meer uit naar herschikkende benaderingen van (deel)objecten uit kunst, architectuur en het alledaagse.
        Bewerking van mijn beelden laat ik in een enkel geval over de grens lopen van het natuurlijke en realistische, op zoek naar de schilderachtige mogelijkheden die in het fotograferen besloten liggen.
      </SecondParagraph>

      <ThirdParagraph>
        Peter van Koningsbruggen
      </ThirdParagraph>
    </MainTextContainer>
  );
}

export const MainTextContainer = styled.div`
  margin: auto;
  max-width: 600px;
`

const MainText = styled.p`
  font-size: 14pt;
  line-height: 21pt;
  padding: 10px;

  @media (min-width: ${Breakpoint.s}px) {
    line-height: 23pt;
  }
`

export const FirstParagraph = styled(MainText)`
  font-weight: 400;
  padding-bottom: 0;
`

export const SecondParagraph = styled(MainText)`
  padding: 0 10px;
  font-weight: 300;
`

const ThirdParagraph = styled(MainText)`
  font-weight: 500;
  text-align: center;
`

export default HomePage;
