import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import {
  Switch,
  Route,
  NavLink,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import Gallery from '../components/Gallery';
import LoadingSpinner from '../components/LoadingSpinner';
import { GalleryMargin } from '../utils/sizes';
import { getData } from '../api/api';

function GalleryPage() {
  let match = useRouteMatch();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getData();

      setData(result.data);
    }

    fetchData();
  }, []);

  const topics = data.topics;
  const photos = data.photos;

  if ( topics === undefined || photos === undefined ){
    return (
      <LoadingSpinner />
    );
  }

  return (
    <>
      <TopicLinkContainer>
        <Categories>Topics:</Categories>
        {topics.map((topic, idx) => (
          <TopicLink 
            to={`${match.path}/${topic}`}
            key={idx}
          >
            {topic}
          </TopicLink>
        ))}
      </TopicLinkContainer>

      <Switch>
        <Route path={`${match.path}/:topic`}>
          <Gallery photos={photos}/>
        </Route>
        <Route path={`${match.path}`}>
          <Redirect exact from={`${match.path}`} to={`${match.path}/${data.topics[0]}`} />
        </Route>
      </Switch>
    </>
  );
}

const Categories = styled.span`
  margin-right: 15px;
  margin-left: ${GalleryMargin}px;
  font-weight: 300;
  font-size: 11pt;
  color: #333;
`

const TopicLinkContainer = styled.div`
  border-bottom: 1px solid #333;
  margin-bottom: ${GalleryMargin}px;
`

const TopicLink = styled(NavLink)`
  color: #333;
  padding: 0 10px;
  margin: 0 15px;
  font-size: 13pt;  

  &.active {
    border: 1px solid #333;
    border-bottom: 1px solid #fff; /* #faf9f7 with texture */
    margin-bottom: -2px;
  }
  &.active:hover {
    text-decoration: none;
    cursor: default;
  }
`

export default GalleryPage;
