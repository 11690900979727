import React from "react";
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import GalleryPage from './pages/GalleryPage';
import HomePage from './pages/HomePage';
import Menu from './components/Menu';

function App() {
    return (
      <Router>
        <PageContainer>
          <Menu />

          <Main>
            <Switch>
              <Route path="/gallery" component={GalleryPage} />
	      <Route path="/contact" component={ContactPage} />
	      <Route path="/" component={HomePage} />
            </Switch>
          </Main>
        </PageContainer>
      </Router>
    );
}

//              <Route path="/about" component={AboutPage} />
//              <Route path="/contact" component={ContactPage} />

const PageContainer = styled.div`
  margin: 25px auto 0 auto;
  max-width: 1000px;
  padding: 4px;
`

const Main = styled.div`
  margin-top: 30px;
`

export default App;
