import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Breakpoint } from '../utils/sizes';
import CustomLogo from './CustomLogo';

function Menu({ className }) {
  return (
    <MenuContainer>
      <CustomLogo />
      <MenuItems>
        <StyledMenuLink to="/" text="Home" />
        <StyledMenuLink to="/gallery" text="Gallery" />
	<StyledMenuLink to="/contact" text="Contact" />
      </MenuItems>
    </MenuContainer>
  )
}

const MenuContainer = styled.div`
  position: relative;
  text-align: center;

  @media (min-width: ${Breakpoint.m}px) {
    text-align: initial;
  }
`

const MenuItems = styled.div`
  margin-top: 10px;

  @media (min-width: ${Breakpoint.m}px) {
    margin-top: 0;
    right: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
`

function MenuLink({ className, to, text }) {
  return (
    <NavLink
      exact
      to={to}
      isActive={(match, location) => {
        if (!match) {
          return to !== "/" && location.pathname.includes(to);
        }
        return true;
      }}
      className={className}
    >
      {text}
    </NavLink>
  ) 
};

const StyledMenuLink = styled(MenuLink)`
  margin: 0 5px;
  padding: 10px;
  color: #c00000;
  font-size: 11pt;
  font-variant: small-caps;
  font-weight: 500;

  @media (min-width: ${Breakpoint.s}px) {
    font-size: 12pt;
    margin: 0 15px;
  }

  &.active {
    color: #2f5597;
  }
`

const StyledMenu = styled(Menu)`
  margin: auto;
  max-width: 1000px;
  position: relative;
`;

export default StyledMenu;
